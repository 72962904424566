.App {
  text-align: center;
  font-family: Poppins, sans-serif;
  overflow-x: hidden;
}

*, *:before, *:after {
  box-sizing: border-box;
  /* margin: 0;
  padding: 0; */
}

a {
  text-decoration: none;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Regular'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-VariableFont_wght'), url(./assets/fonts/Raleway-VariableFont_wght.ttf) format('truetype');
}