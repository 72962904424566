
.slider {
    height: 100vh;
    width: 100vw;
    /* background-color: #0a0908; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    overflow: visible; /* changed these values */
    transition: background-color 2s;
}

@media only screen and (max-width: 600px) {
    .slider {
        height: 60vh;
    }
}

.slider .credits {
    position: absolute;
    top: 2rem;
    left: 2rem;
    line-height: 1.65;
    z-index: 10;
    max-width: 320px;
}
.slider .item .imgs {
    position: relative;
    width: 70%; /* changed these values */
    padding-top: 30%; /* changed these values */
    padding-bottom: 30%;
    display: flex;
    flex-direction: column;
}
.slider .item .imgs .grid {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    /* display: flex; */
    grid-template-columns: 1fr 2fr; /* changed these values from: repeat(12, 1fr) */
    /* grid-template-rows: 1fr;  /* changed these values from: repeat(12, 1fr) */
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    transform: rotate(-20deg);
    opacity: 0.65;
}
/********** added grid-1 and grid-2 **********/
.slider .item .imgs .grid-1 {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    display: grid;
    grid-template-columns: 2fr 4fr; 
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    transform: rotate(-20deg);
    opacity: 0.85;
}
.slider .item .imgs .grid-2 {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    margin-top: 32px;
    display: grid;
    grid-template-columns: 4fr 2fr; 
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    transform: rotate(-20deg);
    opacity: 0.85;
}
.slider .item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slider .item .img {
    width: 100%;
    height: 100%;
    position: relative;
    will-change: transform;
    will-change: opacity;
}
.slider .item .img img {
    /* position: absolute; */
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* consider changing these values */
    position: relative;
    -webkit-filter: contrast(110%) brightness(110%) saturate(130%);
    filter: contrast(110%) brightness(110%) saturate(130%);
}
.slider .item .img img::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: screen;
    background: rgba(243, 106, 188, 0.3);
}
.slider .item .img-1 {
    grid-area: 0.0285714286;
}
.slider .item .img-2 {
    grid-area: 0.0043956044;
    padding-top: 32px;
}
.slider .item .img-3 {
    grid-area: 0.0648148148;
    padding-bottom: 32px;
}
.slider .item .img-4 {
    grid-area: 0.0046022354;
}
.slider .item .content {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.15;
    font-size: 8rem;
    font-weight: 700;
}
.slider .item .content .wrap {
    text-align: center;
    text-shadow: 1px 1px 4px rgba(10, 9, 8, 0.2);
    width: 100%;
    max-width: 600px;
    line-height: 1;
}
.slider .item .content .wrap .letter {
    display: inline-block;
}
.slider .nav .next, .slider .nav .prev {
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: calc(50% - 1rem);
    cursor: pointer;
    z-index: 3;
    transition: transform 0.3s;
}
.slider .nav .next {
    right: 2rem;
    background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M 19 8 L 19 11 L 1 11 L 1 13 L 19 13 L 19 16 L 23 12 L 19 8 z' fill='white'/%3E%3C/svg%3E");
}
.slider .nav .next:hover {
    transform: translateX(0.5rem);
}
.slider .nav .prev {
    left: 2rem;
    background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M 5 8 L 1 12 L 5 16 L 5 13 L 23 13 L 23 11 L 5 11 L 5 8 z' fill='white'/%3E%3C/svg%3E");
}
.slider .nav .prev:hover {
    transform: translateX(-0.5rem);
}
.slider .nav .explore-btn {
    z-index: 4;
    position: absolute;
    bottom: 2rem;
    left: calc(50% - 4rem);
    width: 8em;
    text-align: center;
    padding: 1rem 0;
    border: solid 2px white;
    background: transparent;
    color: white;
    transition: background-color 0.3s;
    cursor: pointer;
}
.slider .nav .explore-btn:hover {
    color: #0a0908;
    background: white;
}
.slider .item:not(.is-active) {
    opacity: 0;
    pointer-events: none;
}
