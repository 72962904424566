.radial-progress-bar {
    border-radius: 50%;
    /* -webkit-transform: translate(50%, 50%);
            transform: translate(50%, 50%); */
    width: 150px;
    height: 150px;
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex; */
    background: #fff;
    /* margin: 20px; */
}

.radial-progress-bar .inner-circle {
    border-radius: 50%;
    width: 116px;
    height: 116px;
    margin: 0;
    /* margin: "30%"; */
    background: #fff;
    text-align: center;
    /* padding-top: 30%; */
    /* padding: 30%; */
}

/* sizing for md and smaller, from mui default breakpoint sizes */
@media only screen and (max-width: 900px) {
    .radial-progress-bar {
        width: 100px;
        height: 100px;
    }
    .radial-progress-bar .inner-circle {
        width: 82px;
        height: 82px;
        
    }
}

.progress-1 {
    background-image: -webkit-linear-gradient(90deg, #fff 50%, transparent 50%), -webkit-linear-gradient(left, #fff 50%, #5B91FE 50%);
    background-image: linear-gradient(0deg, #fff 50%, transparent 50%), linear-gradient(90deg, #fff 50%, #5B91FE 50%);
    /* TODO: animate progress bar change with animejs? see: object properties */
    /* to change progress bar amount: 
    for page 2 (50%):
    linear-gradient(0deg --> 90deg, #fff 50%, transparent 50%), linear-gradient(90deg, #fff 50%, #5B91FE 50%);
    90deg --> 0deg for webkit version

    for page 3 (100%):
    linear-gradient(-90deg --> 90deg, #5B91FE 50%, transparent 50%),
    opposite for webkit version
    */
}

.progress-2 {
    background-image: -webkit-linear-gradient(0deg, #fff 50%, transparent 50%), -webkit-linear-gradient(left, #fff 50%, #5B91FE 50%);
    background-image: linear-gradient(90deg, #fff 50%, transparent 50%), linear-gradient(90deg, #fff 50%, #5B91FE 50%);
}

.progress-3 {
    background-image: -webkit-linear-gradient(0deg, #5B91FE 50%, transparent 50%), -webkit-linear-gradient(left, #fff 50%, #5B91FE 50%);
    background-image: linear-gradient(90deg, #5B91FE 50%, transparent 50%), linear-gradient(90deg, #fff 50%, #5B91FE 50%);
}