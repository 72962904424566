.parallax-effect-img {
    transform-style: preserve-3d;
    transform: perspective(1000px);
    background-image: url('../../assets/images/Cloud.png');
    background-size: cover;
    background-repeat: no-repeat;
}

/* .parallax-effect-img .inner-element {
    transform: translateZ(40px) scale(0.8);
    max-width: 30rem;
    padding-left: 25%;
    padding-right: 25%;
} */